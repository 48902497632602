import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/zoom';

import type { SwiperOptions } from 'swiper/types';

import { Swiper } from 'swiper';
import { Navigation, Zoom } from 'swiper/modules';

export const carouselContainerClass = 'swiper';
export const carouselWrapperClass = 'swiper-wrapper';
export const carouselItemClass = 'swiper-slide';
export const zoomContainerClass = 'swiper-zoom-container';

export type UseCarouselOptions = Omit<SwiperOptions, 'modules'> & {
	shouldWatchCarouselContainerElement?: boolean;
	currentIndex?: number;
};

const defaultModules = [Navigation];

export function useCarousel(swiperOptions: MaybeRef<UseCarouselOptions>) {
	const swiper = ref<Swiper | null>(null);

	const carouselContainer = ref<HTMLElement | null>(null);

	const { currentIndex: initialIndex } = unref(swiperOptions);

	const currentImageCarouselIndex = ref<number>(initialIndex || 0);

	const canSlideToPrevious = computed<boolean>(() => {
		if (!swiper.value) return false;

		return currentImageCarouselIndex.value > 0;
	});

	const canSlideToNext = computed<boolean>(() => {
		if (!swiper.value) return false;

		return !swiper.value.isEnd;
	});

	const unwatchCarouselContainer = watch(
		carouselContainer,
		containerElement => {
			if (!containerElement) return;

			const swiperOptionsValue = unref(swiperOptions);

			const modules = swiperOptionsValue.zoom
				? [...defaultModules, Zoom]
				: defaultModules;

			swiper.value = new Swiper(containerElement, {
				...swiperOptionsValue,
				modules
			});

			if (currentImageCarouselIndex.value) {
				swiper.value.slideTo(currentImageCarouselIndex.value);
			}

			swiper.value.on('slideChange', ({ activeIndex }) => {
				currentImageCarouselIndex.value = activeIndex;
			});

			if (!swiperOptionsValue.shouldWatchCarouselContainerElement) {
				unwatchCarouselContainer();
			}
		},
		{ immediate: true }
	);

	function goToNextImage() {
		if (!swiper.value) return;

		swiper.value.slideNext();
	}

	function goToPreviousImage() {
		if (!swiper.value) return;

		swiper.value.slidePrev();
	}

	function goTo(index: number) {
		if (!swiper.value) return;

		swiper.value.slideTo(index);
	}

	if (isRef(swiperOptions)) {
		watch(
			() => swiperOptions.value.breakpoints,
			() => {
				if (!carouselContainer.value) return;

				const modules = swiperOptions.value.zoom
					? [...defaultModules, Zoom]
					: defaultModules;

				swiper.value = new Swiper(carouselContainer.value, {
					...swiperOptions.value,
					modules
				});
			},
			{ deep: true }
		);
	}

	const carousel = reactive({
		currentIndex: readonly(currentImageCarouselIndex),
		goToNextImage,
		goToPreviousImage,
		goTo,
		canSlideToPrevious,
		canSlideToNext
	});

	return {
		carouselContainer,
		carouselContainerClass,
		carouselWrapperClass,
		carouselItemClass,
		zoomContainerClass,
		carousel
	};
}
